<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="(tab) in tabs"
        v-show="showOrHideTab(tab)"
        :key="tab.id"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon color="warning">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <!-- <v-tab-item /> -->

      <v-tab-item>
        <account-settings-general
          :account-data="accountData"
          :subtype="subtype"
          :status="status"
          :mode="mode"
        >
        </account-settings-general>
      </v-tab-item>

      <v-tab-item>
        <account-settings-permissions
          :account-data="accountData"
          :mode="mode"
          :scopes="scopes_filtered"
          @trigger-sync-scopes-permissions="syncScopesAndPermissions"
        ></account-settings-permissions>
      </v-tab-item>

      <v-tab-item v-show="showOrHideTab({id: 'oauth2'})">
        <account-settings-o-auth2
          :account-data="accountData"
          :mode="mode"
          :scopes="scopes"
          @trigger-sync-scopes-permissions="syncScopesAndPermissions"
        ></account-settings-o-auth2>
      </v-tab-item>

      <v-tab-item v-if="mode === 'update'" v-show="accountData.subtype === 'client'">
        <account-settings-rules
          :account-data="accountData"
          :mode="mode"
        ></account-settings-rules>
      </v-tab-item>

      <v-tab-item v-if="mode === 'update'">
        <enrollments-table
          :filter="filter"
          type="enrollment"
          title="Factor Enrollments"
          :hide-title="false"
        ></enrollments-table>
      </v-tab-item>

      <v-tab-item v-if="mode === 'update'">
        <account-settings-consents
          :account-data="accountData"
          :consents="consents"
          :tenant-consents="tenant_consents"
          :mode="mode"
        ></account-settings-consents>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-divider></v-divider> -->

    <v-card
      v-show="(accountData.subtype === 'client' && tab < 3) || (accountData.subtype === 'user' && tab < 2)"
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid || isLoading"
                @click.prevent="saveAccount"
              >
                Save & Exit
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                :disabled="isLoading"
                @click.prevent="exit"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              v-if="mode === 'update'"
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                :disabled="!accountData.id || isLoading"
                @click="deleteItem"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Invite Token Dialog -->
    <v-dialog
      v-model="dialogInviteToken"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Invite Token
        </v-card-title>
        <v-card-text>
          The invite token is only shown in the clear once upon account creation.<br /><br />
          Note down the invite token:<br /><strong>{{ accountData.invite_token }}</strong><br /><br />
          You can't re-generate an invite token for an existing account.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            text
            @click="exit"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END: Invite Token Dialog -->

    <!-- Factor Output Dialog -->
    <v-dialog
      v-model="dialogFactorOutput"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Factor Ouput
        </v-card-title>
        <v-card-text>
          The secret / private key (JWK) is only shown in the clear so make sure to copy it now and store securily.<br /><br />
          <strong>{{ accountData.config.authentication ? accountData.config.authentication.output : '' }}</strong><br /><br />
          You can always create a new one if needed.<br /><br />
          <!-- <div v-if="accountData.config.grant_types && accountData.config.grant_types.includes('client_credentials')">
            You can generate an <strong>Access Token</strong> for the client via Client Credentials Grant:<br />
            <div class="code_block">
              curl --location --request POST 'https://{{ QUASR_ENV.tenant_id }}.api{{ API_ENV === 'prod' ? '' : '-'.concat(API_ENV) }}.quasr.io/oauth2/token' \<br />
              &nbsp;&nbsp;--header 'Content-Type: application/json' \<br />
              &nbsp;&nbsp;--header 'Authorization: Basic {{ btoa(accountData.id + ':' + (accountData.config.authentication ? accountData.config.authentication.output : '')) }})' \<br />
              &nbsp;&nbsp;--data-raw '{<br />
              &nbsp;&nbsp;&nbsp;&nbsp;"grant_type": "client_credentials",<br />
              &nbsp;&nbsp;&nbsp;&nbsp;"scope": "{{ accountData.config.scopes ? accountData.config.scopes.toString().replaceAll(',', ' ') : '' }}"<br />
              }'
            </div>
          </div> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            text
            @click="exit"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END: Client Secret Dialog -->

    <!-- Delete Confirmation Dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-text v-if="is_admin">
          You are about to delete an admin account. Make sure that there will be enough remaining admin users in order to administrate the tenant.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END: Delete Confirmation Dialog -->
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline, mdiFormatListChecks,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiCogOutline, mdiKeyChain, mdiArrowLeft, mdiHandFrontRightOutline,
  mdiShieldLockOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// import mixpanel from 'mixpanel-browser'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import {
  getAccount,
  listControls,
  listPermissions,
} from '../../graphql/queries'
import {
  updateAccount,
  deleteAccount,
  createAccount,
  createPermission,
} from '../../graphql/mutations'
import AccountSettingsGeneral from './AccountSettingsGeneral.vue'
import AccountSettingsOAuth2 from './AccountSettingsOAuth2.vue'
import AccountSettingsPermissions from './AccountSettingsPermissions.vue'
import AccountSettingsConsents from './ConsentsTable.vue'
import AccountSettingsRules from './AccountSettingsRules.vue'
import EnrollmentsTable from '../enrollment/EnrollmentsTable.vue'
import store from '../../store'

export default {

  /**
   * components
   */
  components: {
    AccountSettingsGeneral,
    AccountSettingsOAuth2,
    EnrollmentsTable,
    AccountSettingsPermissions,
    AccountSettingsConsents,
    AccountSettingsRules,
  },

  /**
   * props
   */
  props: {
    subtype: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      scopes: [],
      scopes_filtered: [],
      consents: [],
      tenant_consents: [],
      dialog: false,
      dialogDelete: false,
      dialogFactorOutput: false,
      dialogInviteToken: false,
      accountOriginal: { config: { permissions: [] } },
      accountData: {
        type: 'account',
        subtype: this.subtype,
        config: {
          audiences: [this.ENV === 'prod' ? 'https://api.quasr.io' : `https://api-${this.ENV}.quasr.io`],
          access_token_expiration: '1d',
          refresh_token_expiration: '1y',
          id_token_expiration: '1d',
          grant_types: [],
          client_authentication: 'none',
        },
      },
      filter: {},
      is_admin: false,
    }
  },

  /**
   * computed
   */
  computed: {

    /**
     * isFormValid
     */
    isFormValid() {
      const missingFields = []
      if (this.mode === 'update' && !this.accountData.id) {
        missingFields.push('ID')
      }
      if (!this.accountData.subtype) {
        missingFields.push('Account Type')
      }
      if (!this.accountData.status) {
        missingFields.push('Status')
      }

      // https://www.regexpal.com/98055
      // eslint-disable-next-line
      // const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      if (this.accountData.config.login_uri) {
        if (!this.accountData.config.login_uri.includes('://')) {
          missingFields.push('Login URI')
        }
      }
      let redirect_uris
      if (this.accountData.config.redirect_uris) {
        redirect_uris = typeof this.accountData.config.redirect_uris === 'object' ? this.accountData.config.redirect_uris : this.accountData.config.redirect_uris.split(',')
        for (let i = 0; i < redirect_uris.length; i += 1) {
          if (!redirect_uris[i].includes('://')) {
            missingFields.push('Redirect URIs')
          }
        }
      }

      if (this.accountData.subtype === 'client') {
        if (!this.isValidExpiration('access')) {
          missingFields.push('Invalid access token expiration')
        }
        if (!this.isValidExpiration('refresh')) {
          missingFields.push('Invalid refresh token expiration')
        }
        if (!this.isValidExpiration('identity')) {
          missingFields.push('Invalid ID token expiration')
        }
        if (!this.accountData.config.tokens?.access?.aud) {
          missingFields.push('Audience(s)')
        }
        if (!this.accountData.config.grant_types || this.accountData.config.grant_types.length === 0) {
          missingFields.push('Grant Types')
        }
        if (this.accountData.config.grant_types && this.accountData.config.grant_types.includes('client_credentials') && this.accountData.config.authentication?.method === 'none') {
          missingFields.push('Client Authentication cannot be none if client_credentials grant types is selected')
        }
        if ((this.accountData.config.authentication?.method?.startsWith('client_secret') || this.accountData.config.authentication?.method === 'private_key_jwt') && !this.accountData.config.authentication?.factor) {
          missingFields.push('Client Authentication Factor')
        }
        if (this.accountData.config.grant_types && this.accountData.config.grant_types.includes('authorization_code') && (!redirect_uris || redirect_uris.length === 0)) {
          missingFields.push('Authorization Code Grant requires at least one valid redirect uri')
        }
        console.log('Missing fields: '.concat(JSON.stringify(missingFields)))
        QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))
      }

      return missingFields.length === 0
    },
  },

  /**
   * watch
   */
  watch: {
    accountData() {
      this.syncScopesAndPermissions()
      this.is_admin = this.accountData?.config?.permissions && this.accountData?.config?.permissions.includes(this.ADMIN_SCOPE)
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    await this.getAccount()
    await this.getScopes()
    if (this.accountData.subtype === 'client' && this.accountData.config.scopes) {
      // filter out old invalid scope names (legacy values from DB)
      this.accountData.config.scopes = this.accountData.config.scopes.filter(s => this.scopes.find(ss => ss.value === s))
    }

    this.filter = { account: this.accountData.id, type: 'enrollment' }

    // filter out old invalid permission names (legacy values from DB)
    if (this.accountData?.config?.permissions) this.accountData.config.permissions = this.accountData.config.permissions.filter(s => this.scopes.find(ss => ss.value === s))

    if (this.accountData.subtype === 'client' && this.mode === 'update') {
      this.showAlert('Allowed scopes are now configured through rules. Rule updates can take a couple seconds to propagate.', 'OK', 'info')
    }

    if (this.accountData?.id === this.loginStatus.sub) {
      this.showAlert('You are currently viewing your own account. Be careful and make sure you do not lock yourself out.', 'OK', 'warning')
    }

    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {

    /**
     * isValidExpiration
     */
    isValidExpiration(fieldName) {
      if (!this.accountData.config.tokens) return false
      const fieldValue = this.accountData.config.tokens[fieldName].exp
      const seconds = fieldValue ? QuasrHelper.convertStringToSeconds(fieldValue, true) : undefined
      const max = QuasrHelper.EXPIRATION_LIMITS[fieldName === 'identity' ? 'id_token_expiration' : `${fieldName}_token_expiration`]

      return !(!parseInt(seconds, 10) || !max || !seconds || seconds < 0 || seconds > max)
    },

    /**
     * btoa
     */
    btoa(val) {
      return btoa(val)
    },

    /**
     * getAccount
     */
    async getAccount() {
      if (this.mode === 'create') {
        this.accountData = {
          subtype: this.subtype || 'user',
          status: 'ENABLED',
          config: {
            grant_types: [],
            authentication: {
              method: 'none',
              output: undefined,
            },
            permissions: [],
            tokens: {
              access: {
                exp: '1d',
                aud: this.ENV === 'prod' ? 'https://api.quasr.io' : `https://api-${this.ENV}.quasr.io`,
              },
              refresh: {
                exp: '1w',
              },
              identity: {
                exp: '5m',
              },
            },
          },
        }
        this.accountOriginal = JSON.parse(JSON.stringify(this.accountData))
      } else {
        try {
          const account = await store.getAppSyncClient().query({ query: gql(getAccount), variables: { id: this.$route.params.id } })
          const permissions = await store.getAppSyncClient().query({ query: gql(listPermissions), variables: { filter: { account: { eq: this.$route.params.id } } } })
          const controls = await store.getAppSyncClient().query({ query: gql(listControls), variables: {} })
          if (!account.data.getAccount.config) account.data.getAccount.config = {}
          account.data.getAccount.config.permissions = []
          for (let i = 0; i < permissions.data.listPermissions.items.length; i += 1) {
            for (let j = 0; j < controls.data.listControls.items.length; j += 1) {
              if (permissions.data.listPermissions.items[i].control === controls.data.listControls.items[j].id) {
                account.data.getAccount.config.permissions.push(controls.data.listControls.items[j].value)
              }
            }
          }
          if (!account.data.getAccount?.config?.authentication) account.data.getAccount.config.authentication = { output: undefined }
          if (account.data.getAccount.status === 'PENDING') {
            this.status.push({ text: 'Pending', value: 'PENDING' })
            this.status = JSON.parse(JSON.stringify(this.status))
          }
          if (account.data.getAccount.config?.authentication?.factor) {
            account.data.getAccount.config.client_secret_factor = account.data.getAccount.config?.authentication?.factor
          }
          this.accountData = account.data.getAccount
          this.accountOriginal = JSON.parse(JSON.stringify(this.accountData))
        } catch (err) {
          QuasrHelper.log(err)
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.exit()
          }
        }
      }
    },

    /**
     * getScopes (controls)
     */
    async getScopes() {
      const scopes = await store.getAppSyncClient().query(
        {
          query: gql(listControls),
          variables: {
            filter: {
              subtype: {
                eq: 'scope',
              },
              status: {
                eq: 'ENABLED',
              },
            },
            limit: 1000,
          },
        },
      )

      this.scopes = JSON.parse(JSON.stringify(scopes.data.listControls.items))

      for (let i = 0; i < this.scopes.length; i += 1) {
        this.scopes[i].disabled = true
      }

      for (let i = 0; i < scopes.data.listControls.items.length; i += 1) {
        if (!scopes.data.listControls.items[i].config.permission_required) {
          scopes.data.listControls.items[i].disabled = true
          if (!this.accountData?.config?.permissions?.includes(scopes.data.listControls.items[i].value)) {
            if (!this.accountData.config.permissions) this.accountData.config.permissions = []
            this.accountData.config.permissions.push(scopes.data.listControls.items[i].value)
          }
        } else scopes.data.listControls.items[i].disabled = false
      }

      this.scopes_filtered = scopes.data.listControls.items

      // this.scopes = scopes.data.listControls.items.filter(s => s.config.permission_required)
    },

    /**
     * syncScopesAndPermissions
     */
    syncScopesAndPermissions() {
      // for now, scopes and permissions for clients are the same, so they get synced in the UI whenver
      // the user changes the values on either side
      /*
      if (this.accountData.subtype === 'client') {
        if (this.tab === 1 && this.accountData.config.scopes !== this.accountData.config.permissions) {
          this.accountData.config.scopes = this.accountData.config.permissions
        } else if (this.tab === 2 && this.accountData.config.scopes !== this.accountData.config.permissions) {
          this.accountData.config.permissions = this.accountData.config.scopes
        }
      }
      */
    },

    /**
     * showOrHideTab
     */
    showOrHideTab(tab) {
      return (tab.id === 'general' || tab.id === 'permissions')
        || (tab.id === 'rules' && this.mode === 'update' && this.accountData.subtype === 'client')
        || (tab.id === 'oauth2' && (document.location.pathname.includes('/clients/') || this.accountData.subtype === 'client'))
        || (tab.id === 'enrollments' && this.mode === 'update')
        || (tab.id === 'consents' && this.$route.params.id !== 'new')
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * back
     */
    back() {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/accounts`)
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * exit
     */
    exit() {
      this.$router.back()
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteAccount()
      store.message = { text: 'Account deleted successfully.', action: 'OK', color: 'success' }
      this.closeDelete()
    },

    /**
     * deleteAccount
     */
    async deleteAccount() {
      this.isLoading = true
      await store.getAppSyncClient().mutate({ mutation: gql(deleteAccount), variables: { input: { id: this.accountData.id } } })
      this.$router.back()
    },

    /**
     * saveAccount
     */
    async saveAccount() {
      this.isLoading = true

      // ==== CREATE ===
      if (this.mode === 'create') {
        const obj = {
          label: this.accountData.label,
          status: this.accountData.status,
          subtype: this.accountData.subtype,
          config: {},
        }
        if (this.accountData.config.redirect_uris && typeof this.accountData.config.redirect_uris === 'string') {
          this.accountData.config.redirect_uris = this.accountData.config.redirect_uris.split(',')
          this.accountData.config.redirect_uris = this.accountData.config.redirect_uris.map(element => element.trim())
        }
        if (this.accountData.config.response_types && typeof this.accountData.config.response_types === 'string') {
          this.accountData.config.response_types = this.accountData.config.response_types.split(',')
          this.accountData.config.response_types = this.accountData.config.response_types.map(element => element.trim())
        }
        if (this.accountData.config.tokens.access.aud && typeof this.accountData.config.tokens.access.aud === 'string') {
          this.accountData.config.tokens.access.aud = this.accountData.config.tokens.access.aud.split(',')
          this.accountData.config.tokens.access.aud = this.accountData.config.tokens.access.aud.map(element => element.trim())
        }
        obj.config = JSON.parse(JSON.stringify(this.accountData.config))

        delete obj.config.permissions

        obj.config.external = this.accountData.config.external || false
        if (obj.config?.authentication?.method === 'none' || obj.config?.authentication?.method === 'session') {
          delete obj.config.authentication?.factor
        }
        try {
          const cmd = await store.getAppSyncClient().mutate({ mutation: gql(createAccount), variables: { input: obj } })
          if (cmd.data.createAccount) {
            // only push permissions that are not implicitly granted already
            for (let i = 0; i < this.accountData.config.permissions.length; i += 1) {
              const p = this.accountData.config.permissions[i]
              const complex_p = this.scopes.find(s => s.value === p)
              if ((complex_p && complex_p.config?.permission_required)) { //  || this.accountOriginal?.config?.permissions?.includes(p[i].value)
                // obj.config.permissions.push(p)
                const permission = {
                  control: complex_p.id,
                  account: cmd.data.createAccount.id,
                  status: 'ENABLED',
                }
                store.getAppSyncClient().mutate({ mutation: gql(createPermission), variables: { input: permission } })
              }
            }
            cmd.data.createAccount.config.permissions = this.accountData.config.permissions
            this.accountData = cmd.data.createAccount
            this.mode = 'update'

            // this.showAlert('Account saved successfully.', 'OK', 'success')
            if (this.accountData.invite_token) {
              this.dialogInviteToken = true
            } else if (this.accountData.config.authentication?.output) {
              this.dialogFactorOutput = true
            } else {
              store.message = { text: 'Account saved successfully.', action: 'OK', color: 'success' }
              this.exit()
            }
          }
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.showAlert(`Saving account failed. ${err}`, 'OK', 'error')
            this.isLoading = false
          }
        }
      } else

      // ==== UPDATE ===
      if (this.mode === 'update') {
        const obj = {
          label: this.accountData.label,
          status: this.accountData.status,
          subtype: this.accountData.subtype,
          config: {
            external: this.accountData.config.external,
          },
        }
        obj.id = this.accountData.id

        if (this.accountData.config.redirect_uris && typeof this.accountData.config.redirect_uris === 'string') {
          this.accountData.config.redirect_uris = this.accountData.config.redirect_uris.split(',')
          this.accountData.config.redirect_uris = this.accountData.config.redirect_uris.map(element => element.trim())
        }
        if (this.accountData.config.response_types && typeof this.accountData.config.response_types === 'string') {
          this.accountData.config.response_types = this.accountData.config.response_types.split(',')
          this.accountData.config.response_types = this.accountData.config.response_types.map(element => element.trim())
        }
        if (this.accountData.config.tokens?.access?.aud && typeof this.accountData.config.tokens.access.aud === 'string') {
          delete this.accountData.config.tokens.access.aud
          this.accountData.config.tokens.access.aud = this.accountData.config.tokens.access.aud.split(',')
          this.accountData.config.tokens.access.aud = this.accountData.config.tokens.access.aud.map(element => element.trim())
        }
        obj.config = JSON.parse(JSON.stringify(this.accountData.config))

        if (obj.status === 'PENDING') delete obj.status
        delete obj.config.permissions
        delete obj.config.scopes
        delete obj.config.client_secret_factor
        if (obj.config?.tokens) delete obj.config.tokens.code
        if (obj.config?.tokens?.access) delete obj.config.tokens.access.use
        if (obj.config?.tokens?.refresh) delete obj.config.tokens.refresh.use
        if (obj.config?.tokens?.identity) delete obj.config.tokens.identity.use
        if (obj.config?.tokens?.access && !obj.config.tokens.access.ext) delete obj.config.tokens.access.ext
        if (obj.config?.tokens?.access && !obj.config.tokens.access.jwk) delete obj.config.tokens.access.jwk
        if (obj.config?.tokens?.identity && !obj.config.tokens.identity.ext) delete obj.config.tokens.identity.ext
        if (obj.config?.tokens?.identity && !obj.config.tokens.identity.jwk) delete obj.config.tokens.identity.jwk

        if (obj.config?.authentication?.method === 'none' || obj.config?.authentication?.method === 'session') {
          delete obj.config.authentication.factor
        }

        // only push permissions that are not implicitly granted already
        for (let i = 0; i < this.accountData.config.permissions.length; i += 1) {
          const p = this.accountData.config.permissions[i]
          const complex_p = this.scopes.find(s => s.value === p)
          if ((complex_p && complex_p.config?.permission_required)) { //  || this.accountOriginal?.config?.permissions?.includes(p[i].value)
            // obj.config.permissions.push(p)
            const permission = {
              control: complex_p.id,
              account: this.accountData.id,
              status: 'ENABLED',
            }
            store.getAppSyncClient().mutate({ mutation: gql(createPermission), variables: { input: permission } })
          }
        }

        try {
          const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateAccount), variables: { input: obj } })
          if (cmd.data.updateAccount) {
            // this.showAlert('Account saved successfully.', 'OK', 'success')
            if (this.accountData.config.authentication?.output || cmd.data.updateAccount.config.authentication?.output) {
              if (cmd.data.updateAccount.config.authentication?.output) this.accountData.config.authentication.output = cmd.data.updateAccount.config.authentication.output
              this.dialogFactorOutput = true
            } else {
              store.message = { text: 'Account saved successfully.', action: 'OK', color: 'success' }
              this.exit()
            }
          }
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.showAlert(`Saving account failed. ${err}`, 'OK', 'error')
            this.isLoading = false
          }
        }
      }
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const isLoading = true
    const ADMIN_SCOPE = API_ENV === 'prod' ? 'https://api.quasr.io/scopes/admin' : `https://api-${API_ENV}.quasr.io/scopes/admin`

    // status options
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    // Mixpanel
    /*
    mixpanel.init('4ab4f868f268ab3290ef0863340eca20', { debug: false })
    mixpanel.track(`tenant:${QUASR_ENV.tenant_id}`)
    mixpanel.track('action:accounts.get')
    */

    // tabs
    const tabs = [
      {
        id: 'general',
        title: document.location.pathname.includes('/clients/') ? 'Client Settings' : 'User Settings',
        icon: document.location.pathname.includes('/clients/') ? mdiRobotOutline : mdiAccountOutline,
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: mdiHandFrontRightOutline,
      },
      {
        id: 'oauth2',
        title: 'OAuth 2.0',
        icon: mdiCogOutline,
      },
      {
        id: 'rules',
        title: 'Rules',
        icon: mdiShieldLockOutline,
      },
      {
        id: 'enrollments',
        title: 'Enrollments',
        icon: mdiKeyChain,
      },
      {
        id: 'consents',
        title: 'Consents',
        icon: mdiFormatListChecks,
      },
    ]
    const tab = ref('')

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      ADMIN_SCOPE,
      isLoading,
      loginStatus,
      status,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiCogOutline,
        mdiKeyChain,
        mdiArrowLeft,
        mdiHandFrontRightOutline,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
