<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ computedIcon }}
      </v-icon>
      <span class="text-break">{{ computedTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.id"
              :label="idFieldlabel"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="idToClipboard"
            ></v-text-field>
          </v-col>

          <v-col
            v-show="mode === 'create' && showType()"
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountData.subtype"
              :rules="requiredRule"
              dense
              outlined
              label="Account Type *"
              :items="subtypes"
              @click.prevent="typeChangedEvent()"
            ></v-select>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.label"
              hide-details="auto"
              :label="labelFieldlabel"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-if="accountData.subtype === 'client' || mode === 'update'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountData.status"
              :rules="requiredRule"
              dense
              outlined
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            v-if="accountData.config"
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="accountData.config.external"
              dense
              outlined
              label="External"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiAccountOutline, mdiRobotOutline, mdiContentCopy,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import { ref } from '@vue/composition-api'

export default {
  /**
   * props
   */
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Array,
      default: () => {},
    },
    type: { type: String, default: undefined },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedTitle
     */
    computedTitle() {
      switch (this.accountData.subtype) {
        case 'client':
          return 'Client Settings'
        case 'user':
          return 'User Settings'
        default:
          return 'Account Settings'
      }
    },

    /**
     * computedIcon
     */
    computedIcon() {
      return this.accountData.subtype === 'client' ? mdiRobotOutline : mdiAccountOutline
    },
  },

  /**
   * methods
   */
  methods: {
    /**
     * typeChanged
     */
    typeChangedEvent() {
      this.$emit('type-changed-event', this.accountData.type)
    },

    /**
     * showType
     */
    showType() {
      return this.$route.path.includes('/accounts/')
    },

    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.accountData.id)
    },
  },

  /**
   * setup
   */
  setup(props) {
    const label = props.subtype === 'client' ? 'Client' : 'Account'
    const idFieldlabel = label.concat(' ID').concat(' (read-only)')
    const labelFieldlabel = label.concat(' Label')

    const isCurrentPasswordVisible = ref(false)
    const subtypes = [
      { text: 'Client', value: 'client' },
      { text: 'User', value: 'user' },
    ]

    return {
      label,
      idFieldlabel,
      labelFieldlabel,
      isCurrentPasswordVisible,
      subtypes,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiAccountOutline,
        mdiRobotOutline,
        mdiContentCopy,
      },
    }
  },
}
</script>
