<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiLockOutline }}
      </v-icon>
      <span class="text-break">OAuth2 Settings</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="12"
            cols="6"
          >
            <p class="text-sm text--primary">
              The settings on this pane are specific to the <strong>OAuth2 Client</strong> account type. Learn more about the <strong>OAuth2 Client</strong> account type in our <a
                href="https://docs.quasr.io"
                target="_blank"
              >documentation</a>.
            </p>
          </v-col>

          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.id"
              label="Client ID (read-only)"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="idToClipboard"
            ></v-text-field>
          </v-col>

          <v-col
            v-show="mode === 'update'"
            cols="12"
            md="6"
          >
            <v-select
              v-if="accountData.config"
              v-model="accountData.config.scopes"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              label="Scopes (now managed through rules)"
              :items="scopes"
              item-text="label"
              item-value="value"
              :disabled="true"
              @change="triggerSyncScopesAndPermissions"
            ></v-select>
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-if="accountData.config"
              v-model="accountData.config.grant_types"
              :rules="requiredForClientRule"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              label="Allowed Grant Types"
              :items="['authorization_code', 'client_credentials', 'refresh_token', 'urn:ietf:params:oauth:grant-type:jwt-bearer']"
              @change="validateForm"
            ></v-select>
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-if="accountData.config"
              v-model="accountData.config.response_types"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              label="Allowed Response Types"
              :items="['none', 'code', 'code id_token', 'id_token']"
            ></v-select>
          </v-col>

          <v-col
            md="12"
            cols="12"
          >
            <v-text-field
              v-if="accountData.config"
              v-model="accountData.config.login_uri"
              label="Login URL"
              placeholder="https:// or http://"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-if="accountData.config"
              v-model="accountData.config.redirect_uris"
              :rules="redirectRule"
              outlined
              placeholder="xyz://"
              rows="3"
              label="Allowed Redirect URIs (comma-separated)"
            ></v-textarea>
            <!-- <div>Note: the first redirect URI entered here is considered the default, if none is provided in an authorize request.</div> -->
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-textarea
              v-if="accountData.config"
              v-model="accountData.config.tokens.access.aud"
              label="Audience(s) (comma-separated) *"
              :rules="requiredForClientRule"
              dense
              outlined
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="accountData.config.tokens.access.exp"
              type="text"
              :rules="accessTokenExpRule"
              outlined
              dense
              label="Access Token Expiration (max. 30d)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs) / d(ays) /w(eeks) /y(rs)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="accountData.config.tokens.refresh.exp"
              type="text"
              :rules="refreshTokenExpRule"
              outlined
              dense
              label="Refresh Token Expiration (max. 1y)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs) / d(ays) /w(eeks) /y(rs)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="accountData.config.tokens.identity.exp"
              type="text"
              :rules="identityTokenExpRule"
              outlined
              dense
              label="ID Token Expiration (max. 1d)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs) / d(ays) /w(eeks) /y(rs)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.tokens.identity.ext"
              dense
              outlined
              clearable
              label="ID Token Extension"
              hide-details="auto"
              :items="extensions"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.tokens.access.ext"
              dense
              outlined
              clearable
              label="Access Token Extension"
              hide-details="auto"
              :items="extensions"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.tokens.identity.jwk"
              label="ID Token Encryption (JWKS URL)"
              placeholder="https://example.com/jwks"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.tokens.access.jwk"
              label="Access Token Encryption (JWKS URL) (Experimental)"
              placeholder="https://example.com/jwks"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.authentication.method"
              dense
              outlined
              label="Client Authentication *"
              :rules="clientAuthRule"
              hide-details="auto"
              :items="client_authentications"
              item-text="text"
              item-value="value"
              @change="validateForm"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-if="accountData.subtype==='client' && accountData.config.authentication.method && accountData.config.authentication.method.startsWith('client_secret')"
              v-model="accountData.config.authentication.factor"
              dense
              outlined
              label="Authentication Factor *"
              hide-details="auto"
              :rules="clientAuthFactorRule"
              :items="client_authentication_factors"
              item-text="text"
              item-value="id"
            ></v-select>
            <v-select
              v-else-if="accountData.subtype==='client' && accountData.config.authentication.method && accountData.config.authentication.method === 'private_key_jwt'"
              v-model="accountData.config.authentication.factor"
              dense
              outlined
              label="Authentication Factor *"
              hide-details="auto"
              :rules="clientAuthFactorRule"
              :items="client_authentication_factors_jwt"
              item-text="text"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.jwks_uri"
              label="JWKS URL"
              placeholder="https://example.com/jwks"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-show="accountData.subtype==='client'"
              v-model="accountData.config.signed_request"
              dense
              outlined
              label="Signed Request"
              title="Whether this client must send signed requests."
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

// import { AWSAppSyncClient } from 'aws-appsync'
// import { v4 as uuid } from 'uuid'
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiProtocol, mdiLockOutline, mdiContentCopy,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { listExtensions, listFactors, listEnrollments } from '../../graphql/queries'
import store from '../../store'

export default {
  /**
   * props
   */
  props: {
    /**
     * accountData
     */
    accountData: {
      type: Object,
      default: () => {},
    },
    scopes: {
      type: Array,
      default: () => [],
    },
  },

  /**
   * data
   */
  data() {
    return {
      client_authentication_factors: [],
      client_authentication_factor_jwt: [],
      extensions: [],
      jwt: '',
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      requiredRule: [
        value => !!value || 'Required',
      ],
      requiredForClientRule: [
        value => (this.accountData.subtype === 'client' && !!value && value.length > 0) || 'Required',
      ],
      accessTokenExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.access_token_expiration) || 'Required and max. 30d',
      ],
      refreshTokenExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.refresh_token_expiration) || 'Required and max. 1w',
      ],
      identityTokenExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.id_token_expiration) || 'Required and max. 1d',
      ],
      clientAuthRule: [
        value => value !== 'none' || !this.accountData.config.grant_types.includes('client_credentials') || 'Can\'t be set to "None" if Client Credentials grant type is allowed',
      ],
      clientAuthFactorRule: [
        value => (!!value || !(this.accountData.config.authentication.method?.startsWith('client_secret') || this.accountData.config.authentication.method === 'private_key_jwt')) || 'Required',
      ],
      redirectRule: [
        value => ((!this.accountData.config.grant_types.includes('authorization_code') && this.validateRedirectUris()) || (!!value && this.validateRedirectUris())) || 'Authorization Code Grant requires at least one valid redirect uri',
      ],
    }
  },

  /**
   * created
   */
  async created() {
    await Promise.all([this.getExtensions(), this.getSecretFactors(), this.getTokenFactors()])
  },

  /**
   * mounted
   */
  mounted() {
    this.validateForm()
  },

  /**
   * methods
   */
  methods: {

    validateRedirectUris() {
      // https://www.regexpal.com/98055
      // eslint-disable-next-line
      // const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      let redirect_uris
      if (this.accountData.config.redirect_uris) {
        redirect_uris = typeof this.accountData.config.redirect_uris === 'object' ? this.accountData.config.redirect_uris : this.accountData.config.redirect_uris.split(',')
        for (let i = 0; i < redirect_uris.length; i += 1) {
          if (!redirect_uris[i].includes('://')) {
            return false
          }
        }
      }

      return true
    },

    /**
     * getExtensions
     */
    async getExtensions() {
      const extensions = await store.getAppSyncClient().query({ query: gql(listExtensions), variables: { limit: 1000 } })

      // when creating a new factor, only show enabled webhooks
      if (this.mode === 'create') {
        extensions.data.listExtensions.items = extensions.data.listExtensions.items.filter(ext => ext.status === 'ENABLED')
      }
      this.extensions = extensions.data.listExtensions.items

      // check if assigned extensions have meanwhile been deleted
      if (this.accountData.config?.tokens.access.ext && !this.extensions.find(ext => ext.id === this.accountData.config?.tokens.access.ext)) {
        this.accountData.config.tokens.access.ext = null
      }
      if (this.accountData.config?.tokens.identity.ext && !this.extensions.find(ext => ext.id === this.accountData.config?.tokens.identity.ext)) {
        this.accountData.config.tokens.identity.ext = null
      }
    },

    /**
     * getTokenFactors
     */
    async getTokenFactors() {
      let result = []
      if (this.mode === 'update') {
        const enrollments = await store.getAppSyncClient().query({ query: gql(listEnrollments), variables: { filter: { account: { eq: this.accountData.id }, subtype: { beginsWith: 'jwt' } }, limit: 1000 } })
        const x = enrollments.data.listEnrollments.items
        for (let i = 0; i < x.length; i += 1) {
          if (x.subtype !== 'jwt:bearer') {
            x[i].text = `${x[i].label} (${x[i].id})`
          }
        }
        if (x.length > 0) {
          result = ([{ header: 'Use existing enrollment:', divider: true }]).concat(x)
        }
      }

      const factors = await store.getAppSyncClient().query({ query: gql(listFactors), variables: { filter: { status: { eq: 'ENABLED' }, subtype: { beginsWith: 'jwt' } }, limit: 1000 } })

      const y = factors.data.listFactors.items
      for (let i = 0; i < y.length; i += 1) {
        if (y.subtype !== 'jwt:bearer') {
          y[i].text = `${y[i].label} (${y[i].id})`
        }
      }
      if (y.length > 0) {
        result = result
          .concat([{ header: 'Enroll via factor:', divider: true }])
          .concat(y)
      }
      this.client_authentication_factors_jwt = result
    },

    /**
     * getSecretFactors
     */
    async getSecretFactors() {
      let result = []
      if (this.mode === 'update') {
        const enrollments = await store.getAppSyncClient().query({ query: gql(listEnrollments), variables: { filter: { account: { eq: this.accountData.id }, subtype: { eq: 'secret:password' } }, limit: 1000 } })
        const x = enrollments.data.listEnrollments.items
        for (let i = 0; i < x.length; i += 1) {
          x[i].text = `${x[i].label} (${x[i].id})`
        }
        if (x.length > 0) {
          result = ([{ header: 'Use existing enrollment:', divider: true }]).concat(x)
        }
      }

      const factors = await store.getAppSyncClient().query({ query: gql(listFactors), variables: { filter: { status: { eq: 'ENABLED' }, subtype: { eq: 'secret:password' } }, limit: 1000 } })

      const y = factors.data.listFactors.items
      for (let i = 0; i < y.length; i += 1) {
        y[i].text = `${y[i].label} (${y[i].id})`
      }
      if (y.length > 0) {
        result = result
          .concat([{ header: 'Generate new secret via factor:', divider: true }])
          .concat(y)
      }
      this.client_authentication_factors = result
    },

    /**
     * validateForm
     */
    validateForm() {
      this.$refs.form.validate()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.accountData.id)
    },

    /**
     * triggerSyncScopesAndPermissions
     */
    triggerSyncScopesAndPermissions() {
      this.$emit('trigger-sync-scopes-permissions')
    },

    /**
     * computedClientSecret
     */
    computedClientSecret() {
      return this.accountData.config.client_secret
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const isCurrentPasswordVisible = ref(false)
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]
    const client_authentications = [
      { text: 'None', value: 'none' },
      { text: 'Client Secret (Basic)', value: 'client_secret_basic' },
      { text: 'Client Secret (Post)', value: 'client_secret_post' },
      { text: 'Private Key JWT', value: 'private_key_jwt' },
      { text: 'Session', value: 'session' },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      loginStatus,
      isCurrentPasswordVisible,
      status,
      client_authentications,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiProtocol,
        mdiLockOutline,
        mdiContentCopy,
      },
    }
  },
}
</script>
