<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiHandFrontRightOutline }}
      </v-icon>
      <span class="text-break">Permissions</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-if="accountData.config"
              v-model="accountData.config.permissions"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              label="Permissions"
              :items="scopes"
              item-text="label"
              item-value="value"
              @change="triggerSyncScopesAndPermissions"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiHandFrontRightOutline, mdiAccountMultipleCheck,
} from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
    scopes: {
      type: Array,
      default: () => [],
    },
    type: { type: String, default: undefined },
    mode: { type: String, default: undefined },
    tabsHandler: { type: String, default: undefined },
  },

  /**
   * methods
   */
  methods: {
    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * triggerSyncScopesAndPermissions
     */
    triggerSyncScopesAndPermissions() {
      this.$emit('trigger-sync-scopes-permissions')
    },
  },
  setup() {
    return {
      icons: {
        mdiHandFrontRightOutline,
        mdiAccountMultipleCheck,
      },
    }
  },
}
</script>
