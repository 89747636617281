<template>
  <v-row>
    <!-- basic -->
    <v-col
      cols="12"
      title=""
    >
      <v-card title="">
        <v-card-title v-show="!hideTitle">
          {{ title }}
        </v-card-title>

        <v-card>
          <!-- loading icon -->
          <div
            v-show="isLoading"
            id="misc"
          >
            <div class="page-title text-center justify-center px-5">
              <div class="misc-character d-flex justify-center">
                <v-img
                  max-width="100"
                  margin="100"
                  src="@/assets/images/misc/loading-spinner-dark.gif"
                ></v-img>
              </div>
            </div>
          </div>
          <v-col cols="12">
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon :color="alert.color">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-data-table
            v-show="!isLoading"
            :items="factors"
            :headers="computedHeaders"
            :search="search"
            item-key="id"
            class="elevation-1"
            xxx-sort-by="subtype"
            :sort-by="['label']"
            :sort-desc="[false, true]"
            xxx-multi-sort
            xxx-group-by="subtype"
            xxx-show-group-by
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showUUIDs"
                  label="Detailed View"
                  class="mt-2"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="title === 'Factors' || (title === 'Enrollments' && isSecuritySection)"
                  color="primary"
                  dark
                  class="mb-2"
                  @click.prevent="createEnrollment"
                >
                  New Enrollment
                </v-btn>
              </v-toolbar>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      outlined
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon
                v-if="item.status !== 'LOCKED'"
                :disabled="filter.account && filter.account !== loginStatus.sub"
                small
                class="mr-2"
                title="Details"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <v-icon
                v-if="item.status !== 'LOCKED'"
                :disabled="filter.account && filter.account !== loginStatus.sub"
                small
                title="Delete Item"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:item.subtype="{item}">
              <span>{{ subtypes[item.subtype] }}</span>
            </template>
            <template v-slot:item.status="{item}">
              <span>{{ status[item.status] }}</span>
            </template>
            <template v-slot:item.created_at="{item}">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:item.updated_at="{item}">
              <span>{{ item.updated_at ? new Date(item.updated_at).toLocaleString() : '' }}</span>
            </template>
            <template v-slot:item.icon="{item}">
              <span><v-icon>{{ getFactorIcon(item) }}</v-icon></span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as QuasrHelper from '@quasr-io/helper'
import {
  mdiMagnify,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiFacebook,
  mdiGithub,
  mdiGoogle,
  mdiMicrosoft,
  mdiApple,
  mdiLinkedin,
  mdiSlack,
  mdiFormTextboxPassword,
  mdiClockOutline,
  mdiIdentifier,
  mdiLockClock,
  mdiNumeric,
  mdiLockQuestion,
  mdiNumeric2CircleOutline,
  mdiAlphaQCircle,
  mdiAccountKeyOutline,
  mdiCloudKeyOutline,
  mdiAccountStarOutline,
  mdiController,
} from '@mdi/js'
import gql from 'graphql-tag'
import { listFactors, listEnrollments } from '../../graphql/queries'
import { deleteEnrollment } from '../../graphql/mutations'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Factors',
  props: {
    type: {type: String, default: undefined},
    title: {type: String, default: 'Factors'},
    hideTitle: {type: Boolean, default: false},
    filter: {type: Object, default: undefined},
  },

  /**
   * data
   */
  data() {
    return {
      search: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '',
          value: 'icon',
        },
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Label',
          sortable: true,
          filterable: true,
          value: 'label',
        },
        {
          text: 'Subtype',
          sortable: true,
          filterable: true,
          value: 'subtype',
        },
        {
          text: 'Factor',
          sortable: true,
          filterable: true,
          value: 'factor',
        },
        {
          text: 'Created at',
          sortable: true,
          filterable: true,
          value: 'created_at',
        },
        {
          text: 'Updated at',
          sortable: true,
          filterable: true,
          value: 'updated_at',
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'status',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
        },
      ],
      factors: [],
      factor: {
        name: '',
        type: '',
      },
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedHeaders
     */
    computedHeaders () {
        return this.headers.filter((h) => (!['id', 'created_at', 'updated_at', 'factor'].includes(h.value)) || this.showUUIDs)
    }
  },

  /**
   * watch
   */
  watch: {
    showUUIDs() {
      localStorage.setItem('showUUIDs', this.showUUIDs)
    },
    filter() {
      this.getFactors()
    },
    type() {
      this.getFactors()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    isLoading(val) {
      // emit isLoading to parent
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    if (this.uiMode === 'account' && !store.customer) {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/billing`)
    }
    await this.getFactors()

    // display message if available
    if (store.message) {
      const msg = store.message.text.concat('')
      this.showAlert(msg, store.message.action, store.message.color)
      store.message = undefined
    } else if (this.filter.account && this.filter.account === this.loginStatus.sub && document.location.pathname.endsWith('/security')) {
      this.showAlert('You are currently viewing the authentication factors of your own account. Be careful and make sure you do not lock yourself out.', 'OK', 'warning')
    }

    this.isLoading = false
  },

  /**
   * setup
   */
  setup(props) {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const status = {
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      PENDING: 'Pending',
      LOCKED: 'Locked',
    }
    const isSecuritySection = document.location.pathname.endsWith('/security')
    const showUUIDs = localStorage.getItem('showUUIDs') ? localStorage.getItem('showUUIDs') === 'true' : false
    const subtypes = {
      'secret:id': 'Username',
      totp: 'Authenticator App (Time-Based One-Time Password)',
      otp: 'One-Time Password',
      'secret:password': 'Password',
      'jwt:spki': 'Private Key',
      'jwt:jwks': 'Hosted Key Set',
      'jwt:bearer': 'Personal Token',
      oauth2: 'OAuth2',
      'oauth2:waad': 'Azure AD',
      'oauth2:google': 'Google',
      'oauth2:apple': 'Apple',
      'oauth2:github': 'Github',
      'oauth2:linkedin': 'LinkedIn',
      'oauth2:slack': 'Slack',
      'oauth2:facebook': 'Facebook',
      'oauth2:microsoft': 'Microsoft',
      'oauth2:oidc': 'OpenID Connect',
      'oauth2:quasr': 'Quasr',
      'oauth2:discord': 'Discord',
    }
    const isLoading = true

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      isLoading,
      uiMode,
      showUUIDs,
      loginStatus,
      status,
      subtypes,
      isSecuritySection,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiFormTextboxPassword,
        mdiClockOutline,
        mdiFacebook,
        mdiGithub,
        mdiGoogle,
        mdiMicrosoft,
        mdiApple,
        mdiLinkedin,
        mdiSlack,
        mdiNumeric2CircleOutline,
        mdiLockQuestion,
        mdiAlphaQCircle,
        mdiAccountKeyOutline,
        mdiCloudKeyOutline,
        mdiAccountStarOutline,
        mdiController
      },
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * getFactors
     */
    async getFactors() {
      this.isLoading = true
      const additionalFilter = this.filter
      let f = {}
      // if (additionalFilter && additionalFilter.type) {
      //   f.type = {eq: additionalFilter.type}
      // }
      if (additionalFilter && additionalFilter.account) {
        f.account = {eq: this.filter.account}
      }
      if (additionalFilter && additionalFilter.factor) {
        f.factor = {eq: this.filter.factor}
      }
      const factors = await store.getAppSyncClient().query({
        query: this.type === 'enrollment' ? gql(listEnrollments) : gql(listFactors),
        variables: {
          filter: f.account || f.factor ? f : undefined,
          limit: 1000,
        },
      })
      this.factors = this.type === 'enrollment' ? factors.data.listEnrollments.items : factors.data.listFactors.items
      this.isLoading = false
    },

    /**
     * getFactorIcon
     *
     * Returns the proper icon for a given factor, in the factor selection list.
     * For social factors, all of subtype 'oauth2', we decide the icon based on the name.
     * For Quasr, we always name the enrollments with the IDP prepended.
     *
     * @param factor Factor for which to show the icon; relevant attributes are subtype and name
     */
    getFactorIcon(factor) {
      switch (factor.subtype) {
        case 'secret:id':
          return mdiIdentifier
        case 'secret:password':
          return mdiFormTextboxPassword
        case 'otp':
          return mdiNumeric
        case 'totp':
          return mdiLockClock
        case 'jwt:spki':
          return mdiAccountKeyOutline
        case 'jwt:jwks':
          return mdiCloudKeyOutline
        case 'jwt:bearer':
          return mdiAccountStarOutline
        case 'oauth2:quasr':
          return mdiAlphaQCircle
        case 'oauth2:apple':
          return mdiApple
        case 'oauth2:github':
          return mdiGithub
        case 'oauth2:slack':
          return mdiSlack
        case 'oauth2:linkedin':
          return mdiLinkedin
        case 'oauth2:facebook':
          return mdiFacebook
        case 'oauth2:google':
          return mdiGoogle
        case 'oauth2:microsoft':
          return mdiMicrosoft
        case 'oauth2:discord':
          return mdiController
        default:
          return mdiNumeric2CircleOutline
      }
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * close
     */
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    /**
     * viewItem
     */
    viewItem(item) {
      if (document.location.pathname.endsWith('/security')) {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/security/${item.id}`)
      } else {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/enrollments/${item.id}`)
      }
    },

    /**
     * editItem
     */
    editItem(item) {
      this.viewItem(item)
    },

    /**
     * deleteItem
     */
    deleteItem(item) {
      this.editedIndex = this.factors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteEnrollment(this.editedItem.id)
      this.closeDelete()
    },

    /**
     * deleteEnrollment
     */
    async deleteEnrollment(id) {
      await this.deleteFactor(id)
    },

    /**
     * deleteFactor
     */
    async deleteFactor(id) {
      let deletion = await store.getAppSyncClient().mutate({mutation: gql(deleteEnrollment), variables: {input: {id: id}}})
      if (deletion.data) this.factors.splice(this.editedIndex, 1)
      this.showAlert('Factor successfully deleted.', 'OK', 'success')
      this.getFactors()
    },

    /**
     * createEnrollment
     */
    async createEnrollment() {
      if (document.location.pathname.endsWith('/security')) {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/security/new`)
      } else {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/enrollments/new`)
      }
    },
  },
}
/* eslint-enable */
</script>
