<template>
  <v-row title="">
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiFormatListChecks }}
          </v-icon>
          <span class="text-break">Consents</span>
        </v-card-title>
        <v-card>
          <!-- loading icon -->
          <div
            v-show="isLoading"
            id="misc"
          >
            <div class="page-title text-center justify-center px-5">
              <div class="misc-character d-flex justify-center">
                <v-img
                  max-width="100"
                  margin="100"
                  src="@/assets/images/misc/loading-spinner-dark.gif"
                ></v-img>
              </div>
            </div>
          </div>
          <v-col cols="12">
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon :color="alert.color">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-data-table
            v-show="!isLoading"
            :items="consents"
            :headers="computedHeaders"
            :search="search"
            item-key="id"
            class="elevation-1"
            xxx-sort-by="type"
            :sort-by="['label']"
            :sort-desc="[true, false]"
            xxx-multi-sort
            xxx-group-by="type"
            xxx-show-group-by
            title=""
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showUUIDs"
                  label="Detailed View"
                  class="mt-2"
                ></v-switch>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.account === accountData.id"
                small
                title="Delete Consent"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <span>{{ status[item.status] }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:item.updated_at="{ item }">
              <span>{{ item.updated_at ? new Date(item.updated_at).toLocaleString() : '' }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

// import mixpanel from 'mixpanel-browser'
// import { AWSAppSyncClient } from 'aws-appsync'
// import { v4 as uuid } from 'uuid'
import * as QuasrHelper from '@quasr-io/helper'
import {
  mdiMagnify,
  mdiPencilOutline, mdiDeleteOutline,
  mdiEyeOutline, mdiFormatListChecks,
} from '@mdi/js'
import gql from 'graphql-tag'
import { listConsents } from '../../graphql/queries'
import { deleteConsent } from '../../graphql/mutations'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Consents',

  /**
   * props
   */
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },

  /**
   * data
   */
  data() {

    return {
      search: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Consent ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Consent Label',
          sortable: true,
          filterable: true,
          value: 'label',
        },
        {
          text: 'Recipient (Client or Tenant)',
          sortable: true,
          filterable: true,
          value: 'recipient',
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'status',
        },
        {
          text: 'Created at',
          sortable: true,
          filterable: true,
          value: 'created_at',
        },
        {
          text: 'Updated at',
          sortable: true,
          filterable: true,
          value: 'updated_at',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
        },
      ],
      consents: [],
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedHeaders
     */
    computedHeaders () {
        return this.headers.filter((h) => (!['id', 'created_at', 'updated_at'].includes(h.value)) || this.showUUIDs)
    }
  },

  /**
   * watch
   */
  watch: {
    showUUIDs() {
      localStorage.setItem('showUUIDs', this.showUUIDs)
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    await this.getConsents()
    this.isLoading = false
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const showUUIDs = localStorage.getItem('showUUIDs') ? localStorage.getItem('showUUIDs') === 'true' : false
    const status = {
      'ENABLED': 'Enabled',
      'DISABLED': 'Disabled',
    }
    const isLoading = true

    // Mixpanel
    /*
    mixpanel.init('4ab4f868f268ab3290ef0863340eca20', { debug: false })
    mixpanel.track(`tenant:${QUASR_ENV.tenant_id}`)
    mixpanel.track('action:account.consents')
    */

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      showUUIDs,
      isLoading,
      status,
      loginStatus,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiFormatListChecks,
      },
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * selectAccount
     */
    async selectAccount(account) {
      this.$emit('select-account', account)
    },

    /**
     * getConsents
     */
    async getConsents() {
      const consents = await store.getAppSyncClient().query(
        {
          query: gql(listConsents),
          variables: {
            filter: {
              account: { eq: this.accountData.id }
            },
            limit: 1000
          }
        })
      const tenant_consents = await store.getAppSyncClient().query({ query: gql(listConsents), variables: { filter: { account: { eq: this.loginStatus.ten } }, limit: 1000 } })
      this.tenant_consents = tenant_consents.data.listConsents.items
      this.consents = consents.data.listConsents.items.concat(tenant_consents.data.listConsents.items)
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * close
     */
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    /**
     * deleteItem
     */
    deleteItem(item) {
      this.editedIndex = this.consents.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteConsent(this.editedItem.id)
      this.closeDelete()
    },

    /**
     * deleteConsent
     */
    async deleteConsent(id) {
      this.isLoading = true
      const deletion = await store.getAppSyncClient().mutate({ mutation: gql(deleteConsent), variables: { input: { id: id } } })
      if (deletion.data) this.consents.splice(this.editedIndex, 1)
      this.showAlert('Account successfully deleted.', 'OK', 'success')
      this.getConsents()
      this.isLoading = false
    },
  },
}
/* eslint-enable */
</script>
